/**
 * This class is mainly taken from https://github.com/angular/angular/blob/0c5b34b4465e16bf51fe34be042f3cb4a3c06782/packages/common/http/src/transfer_cache.ts
 * The differences are:
 * It is possible to exclude certain headers from the cache such as date or age. The reason for this is that repeated universal renders dont generate different etags for caching
 * It is possible to replace the start of urls from server to client
 * The response fields are compressed
 */

import { HttpEvent, HttpHandlerFn, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import {
  ApplicationRef,
  inject,
  StateKey,
  TransferState,
  makeStateKey,
  InjectionToken,
  Provider,
  APP_BOOTSTRAP_LISTENER,
  PLATFORM_ID,
} from '@angular/core';
import { Observable, firstValueFrom, of } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';

type HttpResponseType = HttpRequest<unknown>['responseType'];

enum TransferHttpResponseFields {
  Body,
  Headers,
  Status,
  StatusText,
  Url,
  ResponseType,
}

type TransferHttpResponse = {
  [TransferHttpResponseFields.Body]: unknown;
  [TransferHttpResponseFields.Headers]: { [headerName: string]: string[] };
  [TransferHttpResponseFields.Status]?: number;
  [TransferHttpResponseFields.StatusText]?: string;
  [TransferHttpResponseFields.Url]?: string;
  [TransferHttpResponseFields.ResponseType]?: HttpResponseType;
};

type CacheState = {
  isActive: boolean;
};
const CACHE_STATE = new InjectionToken<CacheState>('HTTP_TRANSFER_STATE_CACHE_STATE');
function provideCacheState(state: CacheState): Provider {
  return {
    provide: CACHE_STATE,
    useValue: state,
  }
}

/**
 * A list of allowed HTTP methods to cache.
 */
const ALLOWED_METHODS = ['GET', 'HEAD'];

export interface TransferHttpCacheConfiguration {
  headers?: {
    exclude?: string[];
  };
  url?: {
    serverClientReplacement?: { [replacementServerUrl: string]: string };
  };
}
const CACHE_CONFIGURATION = new InjectionToken<TransferHttpCacheConfiguration>('HTTP_TRANSFER_STATE_CACHE_STATE');
function provideCacheConfiguration(configuration: TransferHttpCacheConfiguration): Provider {
  return {
    provide: CACHE_CONFIGURATION,
    useValue: configuration,
  }
}

export function transferCacheInterceptorFn(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const {isActive} = inject(CACHE_STATE);

  // Stop using the cache if the application has stabilized, indicating initial rendering
  // is complete.
  if (!isActive || !ALLOWED_METHODS.includes(req.method)) {
    // Cache is no longer active or method is not HEAD or GET.
    // Pass the request through.
    return next(req);
  }

  const configuration = inject(CACHE_CONFIGURATION);

  const transferState = inject(TransferState);
  const url = replaceServerClientUrl(req.url, configuration.url?.serverClientReplacement || {});
  const storeKey = makeCacheKey(req.method, url, req.params, req.responseType);

  const response = transferState.get(storeKey, null);
  if (response) {
    // Request found in cache. Respond using it.
    let body: ArrayBuffer | Blob | string | undefined;

    switch (response[TransferHttpResponseFields.ResponseType]) {
      case 'arraybuffer':
        body = new TextEncoder().encode(response[TransferHttpResponseFields.Body] as string).buffer;
        break;
      case 'blob':
        body = new Blob([response[TransferHttpResponseFields.Body] as BlobPart]);
        break;
      default:
        body = response[TransferHttpResponseFields.Body] as string;
    }

    return of(
      new HttpResponse<unknown>({
        body,
        headers: new HttpHeaders(response[TransferHttpResponseFields.Headers]),
        status: response[TransferHttpResponseFields.Status],
        statusText: response[TransferHttpResponseFields.StatusText],
        url: response[TransferHttpResponseFields.Url],
      }),
    );
  }

  // Request not found in cache. Make the request and cache it.
  return next(req).pipe(
    tap((event: HttpEvent<unknown>) => {
      if (event instanceof HttpResponse) {
        transferState.set<TransferHttpResponse>(storeKey, {
          [TransferHttpResponseFields.Body]: event.body,
          [TransferHttpResponseFields.Headers]: excludeKeys(getHeadersMap(event.headers), configuration.headers?.exclude || []),
          [TransferHttpResponseFields.Status]: event.status,
          [TransferHttpResponseFields.StatusText]: event.statusText,
          [TransferHttpResponseFields.Url]: event.url ? replaceServerClientUrl(event.url, configuration.url?.serverClientReplacement || {}) : '',
          [TransferHttpResponseFields.ResponseType]: req.responseType,
        });
      }
    }),
  );
}

function getHeadersMap(headers: HttpHeaders): { [headerName: string]: string[] } {
  const headersMap: { [headerName: string]: string[] } = {};
  for (const headerName of headers.keys()) {
    const values = headers.getAll(headerName);
    if (values !== null) {
      headersMap[headerName] = values;
    }
  }

  return headersMap;
}

type Headers = { [headerName: string]: string[] };
function excludeKeys(record: Headers, excludeKeys: string[]): Headers  {
  return Object.entries(record)
  .filter(([key]) => !excludeKeys.includes(key))
  .reduce((obj, [key, value]) => (obj[key] = value, obj), {} as Headers);
}

function replaceServerClientUrl(serverUrl: string, replacementUrls: { [replacementServerUrl: string]: string }): string {
  for(const [replacementServerUrl, replacementClientUrl] of Object.entries(replacementUrls)) {
    if (serverUrl.startsWith(replacementServerUrl)) {
      serverUrl = serverUrl.replace(replacementServerUrl, replacementClientUrl);
      break;
    }
  }
  return serverUrl;
}

function makeCacheKey(
  method: string,
  url: string,
  params: HttpParams,
  responseType: HttpResponseType,
): StateKey<TransferHttpResponse> {
  // make the params encoded same as a url so it's easy to identify
  const encodedParams = params
    .keys()
    .sort()
    .map((k) => `${k}=${params.getAll(k)}`)
    .join('&');

  const key = `${(method === 'GET' ? 'G.' : 'H.') + responseType}.${url}?${encodedParams}`;

  const hash = generateHash(key);

  return makeStateKey<TransferHttpResponse>(hash);
}

/**
 * A method that returns a hash representation of a string using a variant of DJB2 hash
 * algorithm.
 *
 * This is the same hashing logic that is used to generate component ids.
 */
function generateHash(value: string): string {
  let hash = 0;

  for (const char of value) {
    hash = Math.imul(31, hash) + char.charCodeAt(0) << 0;
  }

  // Force positive number hash.
  // 2147483647 = equivalent of Integer.MAX_VALUE.
  hash += 2147483647 + 1;

  return hash.toString();
}

export function provideHttpTransferCacheProviders(configuration: TransferHttpCacheConfiguration): Provider[] {
  return [
    provideCacheConfiguration(configuration),
    provideCacheState({isActive: true}),
    {
      provide: APP_BOOTSTRAP_LISTENER,
      multi: true,
      useFactory: () => {
        const platformId = inject(PLATFORM_ID);
        // The cache should never be deactivated on the server
        if (isPlatformServer(platformId)) {
          return () => undefined;
        }

        const appRef = inject(ApplicationRef);
        const cacheState = inject(CACHE_STATE);

        return () => {
          firstValueFrom(appRef.isStable.pipe(first((isStable) => isStable))).then(() => {
            cacheState.isActive = false;
          });
        };
      },
    },
  ]
}
