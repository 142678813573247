import { APP_INITIALIZER, ApplicationConfig, inject, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { UrlSerializer, provideRouter, withInMemoryScrolling, withRouterConfig } from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { provideServiceWorker } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpTransferCacheProviders, transferCacheInterceptorFn } from './providers/transfer-http-cache.interceptor';
import { APP_BASE_HREF } from '@angular/common';
import { captchaServiceConfiguration } from './providers/captcha.service';
import { contentRepositoryServiceConfiguration } from './providers/content-repository.service';
import { dynamicFormsConfigurationToken } from './providers/dynamic-forms.service';
import { GoogleTagManagerService, googleTagManagerServiceConfiguration } from './providers/google-tag-manager.service';
import { haupiaServiceConfiguration } from './providers/haupia.service';
import { MappService, mappConfigurationToken } from './providers/mapp.service';
import { oneTrustServiceConfiguration, OneTrustService } from './providers/one-trust.service';
import { stockMarketServiceConfiguration } from './providers/stock-market.service';
import { CustomUrlSerializer } from './providers/custom-url-serializer';
import { userConsentManagerToken } from './providers/user-consent.service';
import { youTubeProxyConfiguration } from './providers/youtube.service';
import { PageService } from './providers/page.service';
import { SettingsService } from './providers/settings.service';
import { WebmanifestService } from './providers/webmanifest.service';
import { provideComponentFactoryConfiguration } from '@knorr-bremse-portals/ngx-components';
import { provideDefaultHtmlAttributeAllowlist } from '@knorr-bremse-portals/ngx-components/components/html';
import { provideClientHydration, withI18nSupport, withNoHttpTransferCache } from '@angular/platform-browser';
import { provideGlobalErrorHandler } from './providers/global.error-handler';

export const appConfig: ApplicationConfig = {
  providers: [
    provideExperimentalZonelessChangeDetection(),
    provideGlobalErrorHandler(),
    provideRouter(routes,
      withRouterConfig({
        urlUpdateStrategy: environment.firstSpirit ? 'deferred' : 'eager', // With URL Updates the FirstSpirit Content Creator loses the connection to the page store
      }),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      }),
    ),
    provideAnimationsAsync(),
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.serviceWorker,
      registrationStrategy: 'registerImmediately', // This is needed for the universal transfer cache to be cached in the service worker
    }),
    { provide: APP_BASE_HREF, useValue: '/' },
    provideClientHydration(
      withI18nSupport(),
      withNoHttpTransferCache(), // https://github.com/angular/angular/issues/50117
    ),
    provideHttpClient(
      withFetch(),
      withInterceptors([
        transferCacheInterceptorFn,
      ]),
    ),
    provideHttpTransferCacheProviders({...{
        headers: {
          exclude: ['date', 'age'],
        },
      },
      ...environment.transferHttpCache,
    }),
    {
      provide: UrlSerializer, useClass: CustomUrlSerializer,
    },
    provideDefaultHtmlAttributeAllowlist(),
    {
      provide: contentRepositoryServiceConfiguration, useValue: environment.contentRepositoryService,
    },
    {
      provide: captchaServiceConfiguration, useValue: environment.captchaService,
    },
    {
      provide: haupiaServiceConfiguration, useValue: environment.haupiaService,
    },
    {
      provide: googleTagManagerServiceConfiguration, useValue: environment.googleTagManagerService,
    },
    {
      provide: stockMarketServiceConfiguration, useValue: environment.stockService,
    },
    {
      provide: youTubeProxyConfiguration, useValue: environment.youtubeMetadataService,
    },
    {
      provide: oneTrustServiceConfiguration, useValue: environment.oneTrustService,
    },
    {
      provide: userConsentManagerToken, useExisting: OneTrustService,
    },
    {
      provide: dynamicFormsConfigurationToken, useValue: environment.dynamicForms,
    },
    {
      provide: mappConfigurationToken, useValue: environment.mapp,
    },
    provideComponentFactoryConfiguration({
      components: [
      ],
      lazyComponents: [
        ['kb-standalone-link', '[kb-standalone-link]', () => import('@knorr-bremse-portals/ngx-components/components/standalone-link').then(mod => mod.StandaloneLinkComponent)],
        ['kb-table', 'kb-table', () => import('@knorr-bremse-portals/ngx-components/components/table').then(mod => mod.TableComponent)],
        ['kb-button', 'button[kb-button], a[kb-button]', () => import('@knorr-bremse-portals/ngx-components/components/button').then(mod => mod.ButtonComponent)],

        ['icon', 'app-icon', () => import('./components/icon/icon.component').then(mod => mod.IconComponent)],
        ['one-trust-button', 'button[app-one-trust-button]', () => import('./components/one-trust-button/one-trust-button.component').then(mod => mod.OneTrustButtonComponent)],
        ['one-trust-cookie-list', 'app-one-trust-cookie-list', () => import('./components/one-trust-cookie-list/one-trust-cookie-list.component').then(mod => mod.OneTrustCookieListComponent)],
        ['filler', 'app-filler', () => import('./firstspirit-components/filler/filler.component').then(mod => mod.FillerComponent)],
        ['copy-text', 'app-copy-text', () => import('./firstspirit-components/copy-text/copy-text.component').then(mod => mod.CopyTextComponent)],
        ['headline', 'app-headline', () => import('./firstspirit-components/headline/headline.component').then(mod => mod.HeadlineComponent)],
        ['accordion', 'app-accordion', () => import('./firstspirit-components/accordion/accordion.component').then(mod => mod.AccordionComponent)],
        ['quote', 'app-quote', () => import('./firstspirit-components/quote/quote.component').then(mod => mod.QuoteComponent)],
        ['image-gallery', 'app-image-gallery', () => import('./firstspirit-components/image-gallery/image-gallery.component').then(mod => mod.ImageGalleryComponent)],
        ['youtube-playlist', 'app-youtube-playlist', () => import('./firstspirit-components/youtube-playlist/youtube-playlist.component').then(mod => mod.YouTubePlaylistComponent)],
        ['medium', 'app-medium', () => import('./firstspirit-components/medium/medium.component').then(mod => mod.MediumComponent)],
        ['download-list', 'app-download-list', () => import('./firstspirit-components/download-list/download-list.component').then(mod => mod.DownloadListComponent)],
        ['page-intro', 'app-page-intro', () => import('./firstspirit-components/page-intro/page-intro.component').then(mod => mod.PageIntroComponent)],
        ['tabs', 'app-tabs', () => import('./firstspirit-components/tabs/tabs.component').then(mod => mod.TabsComponent)],
        ['dom-table', 'section[app-dom-table]', () => import('./firstspirit-components/dom-table/dom-table.component').then(mod => mod.DomTableComponent)],
        ['info-box', 'app-info-box', () => import('./firstspirit-components/info-box/info-box.component').then(mod => mod.InfoBoxComponent)],
        ['contacts', 'section[app-contacts]', () => import('./firstspirit-components/contacts/contacts.component').then(mod => mod.ContactsComponent)],
        ['teaser-card-collection', 'section[app-teaser-card-collection]', () => import('./firstspirit-components/teaser-card-collection/teaser-card-collection.component').then(mod => mod.TeaserCardCollectionComponent)],
        ['image-collection', 'section[app-image-collection]', () => import('./firstspirit-components/image-collection/image-collection.component').then(mod => mod.ImageCollectionComponent)],
        ['copy-text-with-link-list', 'app-copy-text-with-link-list', () => import('./firstspirit-components/copy-text-with-link-list/copy-text-with-link-list.component').then(mod => mod.CopyTextWithListComponent)],
        ['teaser-overview', 'section[app-teaser-overview]', () => import('./firstspirit-components/teaser-overview/teaser-overview.component').then(mod => mod.TeaserOverviewComponent)],
        ['teaser-image-collection', 'app-teaser-image-collection', () => import('./firstspirit-components/teaser-image-collection/teaser-image-collection.component').then(mod => mod.TeaserImageCollectionComponent)],
        ['header-medium', 'app-header-medium', () => import('./firstspirit-components/header-medium/header-medium.component').then(mod => mod.HeaderMediumComponent)],
        ['rolling-logo-slider', 'app-rolling-logo-slider', () => import('./firstspirit-components/rolling-logo-slider/rolling-logo-slider.component').then(mod => mod.RollingLogoSliderComponent)],
        ['news-teaser-search', 'app-news-teaser-search', () => import('./firstspirit-components/news-teaser-search/news-teaser-search.component').then(mod => mod.NewsTeaserSearchComponent)],
        ['teaser-card-slider', 'section[app-teaser-card-slider]', () => import('./firstspirit-components/teaser-card-slider/teaser-card-slider.component').then(mod => mod.TeaserCardSliderComponent)],
        ['location-finder', 'app-location-finder', () => import('./firstspirit-components/location-finder/location-finder.component').then(mod => mod.LocationFinderComponent)],
        ['split-stage-teaser', 'section[app-split-stage-teaser]', () => import('./firstspirit-components/split-stage-teaser/split-stage-teaser.component').then(mod => mod.SplitStageTeaserComponent)],
        ['arrow-link', 'app-arrow-link', () => import('./firstspirit-components/arrow-link/arrow-link.component').then(mod => mod.ArrowLinkComponent)],
        ['teaser-overview-switcher', 'section[app-teaser-overview-switcher]', () => import('./firstspirit-components/teaser-overview-switcher/teaser-overview-switcher.component').then(mod => mod.TeaserOverviewSwitcherComponent)],
        ['dynamic-email-form', 'app-dynamic-email-form', () => import('./firstspirit-components/dynamic-email-form/dynamic-email-form.component').then(mod => mod.DynamicEmailFormComponent)],
        ['event-teasers', 'app-event-teasers', () => import('./firstspirit-components/event-teasers/event-teasers.component').then(mod => mod.EventTeasersComponent)],
        ['event-calendar', 'app-event-calendar', () => import('./firstspirit-components/event-calendar/event-calendar.component').then(mod => mod.EventCalendarComponent)],
        ['event-search', 'app-event-search', () => import('./firstspirit-components/event-search/event-search.component').then(mod => mod.EventSearchComponent)],
        ['card-collection', 'app-card-collection', () => import('./firstspirit-components/card-collection/card-collection.component').then(mod => mod.CardCollectionComponent)],
        ['header-medium-slider', 'app-header-medium-slider', () => import('./firstspirit-components/header-medium-slider/header-medium-slider.component').then(mod => mod.HeaderMediumSliderComponent)],
        ['stock-market-info', 'section[app-stock-market-info]', () => import('./firstspirit-components/stock-market-info/stock-market-info.component').then(mod => mod.StockMarketInfoComponent)],
        ['text-scroller', 'app-text-scroller', () => import('./firstspirit-components/text-scroller/text-scroller.component').then(mod => mod.TextScrollerComponent)],
        ['button-on-image', 'app-button-on-image', () => import('./firstspirit-components/button-on-image/button-on-image.component').then(mod => mod.ButtonOnImageComponent)],
        ['timeline', 'section[app-timeline]', () => import('./firstspirit-components/timeline/timeline.component').then(mod => mod.TimelineComponent)],
        ['interactive-image', 'app-interactive-image', () => import('./firstspirit-components/interactive-image/interactive-image.component').then(mod => mod.InteractiveImageComponent)],
        ['sticky-hotline', 'app-sticky-hotline', () => import('./firstspirit-components/sticky-hotline/sticky-hotline.component').then(mod => mod.StickyHotlineComponent)],
        ['current-page-search', 'app-current-page-search', () => import('./firstspirit-components/current-page-search/current-page-search.component').then(mod => mod.CurrentPageSearchComponent)],
        ['condolence', 'app-condolence', () => import('./firstspirit-components/condolence/condolence.component').then(mod => mod.CondolenceComponent)],
        ['article-teaser-list','app-article-teaser-list', () => import('./firstspirit-components/article-teaser-list/article-teaser-list.component').then(mod => mod.ArticleTeaserListComponent)],
        ['number-stage','section[app-number-stage]', () => import('./firstspirit-components/number-stage/number-stage.component').then(mod => mod.NumberStageComponent)],
        ['social-media-linklist', 'section[app-social-media-linklist]', ()=> import('./firstspirit-components/social-media-linklist/social-media-linklist.component').then(mod => mod.SocialMediaLinklistComponent)],
        ['iframe', 'app-iframe', () => import('./firstspirit-components/iframe/iframe.component').then(mod => mod.IframeComponent)],
        ['thank-a-thon-teaser', 'section[app-thank-a-thon-teaser]', () => import('./firstspirit-components/thank-a-thon-teaser/thank-a-thon-teaser.component').then(mod => mod.ThankAThonTeaserComponent)],
        ['click-grid', 'app-click-grid', () => import('./firstspirit-components/click-grid/click-grid.component').then(mod => mod.ClickGridComponent)],
      ],
    }),
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: () => {
        inject(SettingsService).init();
        inject(PageService).init();
        inject(OneTrustService).init();
        inject(MappService).init();
        inject(GoogleTagManagerService).init();
        inject(WebmanifestService).init();

        return () => undefined;
      },
    },
  ],
};
