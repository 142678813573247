import { ErrorHandler, Injectable, Provider } from '@angular/core';
import { getLogger } from '@knorr-bremse-portals/ngx-components';
import { Loggers } from '../../express.tokens';


@Injectable()
class GlobalErrorHandler implements ErrorHandler {

  private logger = getLogger(Loggers.Renderer);

  public handleError(error: unknown): void {
    this.logger.error('Global error handler', {
      error,
    });
  }

}

export function provideGlobalErrorHandler(): Provider {
  return {
    provide: ErrorHandler,
    useClass: GlobalErrorHandler,
  }
}
